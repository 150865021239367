import Vue from 'vue'
import VueRouter from 'vue-router'
import mapIndex from '@/views/map/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: mapIndex
  },
  {
    path: '/navi',
    name: 'navi',
    component: ()=>import('@/views/index/index.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
